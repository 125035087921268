// React and related imports
import React, { useContext, useEffect } from "react";
// Context provider
import { KycContextProvider } from "./KycContext";
// Custom components
import ReactDataTable from "../Common/ReactDataTable";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import KycOnboarding from "./KycOnboaridngModal/KycOnboarding/KycOnboarding";
// Utility functions
import posthog from "posthog-js";
import StatusCell from "../../utilities/StatusCell/StatusCell";
import TimeCell from "../../utilities/TimeCell";
import { capturePosthogEventCustom } from "../../utilities/postHogCustomUtils";
// Styles
import "./KycDataTable.scss";

const transactionColumnList = [
  {
    accessor: "decentro_transaction_id",
    name: "Decentro Transaction ID",
    showInitial: true,
  },
  {
    accessor: "response_code",
    name: "Response Code",
    showInitial: true,
  },
  {
    accessor: "timestamp",
    name: "Date-Time",
    cell: TimeCell,
    showInitial: true,
  },
  {
    accessor: "kyc_status",
    name: "KYC Status",
    showInitial: true,
    hasPopup: true,
    cell: StatusCell,
  },
  { accessor: "ocr_status", name: "OCR Status", showInitial: true },
  { accessor: "document_type", name: "Document type", showInitial: true },
  {
    accessor: "customer_reference_number",
    name: "Customer Ref No",
  },
  { accessor: "api_status", name: "API Status" },
];
const KycCTA = ({ openUpiLink }) => {
  return (
    <>
      <button
        className="virtual-accounts-btn"
        style={{ backgroundColor: "#0092ff" }}
        onClick={openUpiLink}
      >
        Send Onboarding Link
      </button>
    </>
  );
};

const KycReactTable = () => {
  const {
    initialLoading,
    isError,
    errorResponse,
    refreshTable,
    setShowModalDialog,
    tableData,
    totalCount,
    filterList,
    fetchData,
    firstApiCall,
  } = useContext(KycContextProvider);

  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (window?.location?.origin && window?.location?.pathname) {
        try {
          capturePosthogEventCustom(
            window.location.origin,
            window.location.pathname,
            "KYC Validate View",
            {
              additionalInfo: "User viewed KYC Validate page",
            }
          );
        } catch (error) {
          console.error("Error capturing Posthog event:", error);
        }
      } else {
        console.warn(
          "Unable to capture event: window.location properties are not fully defined"
        );
      }
    }
  }, []);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);

  function openUpiLink() {
    setShowModalDialog(true);
  }
  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={"Validate"}
          columnList={transactionColumnList}
          isModalPresent={false}
          modalComponent={<KycCTA openUpiLink={openUpiLink} />}
          provider={KycContextProvider}
          ModalDialog={<KycOnboarding goBackToTable={refreshTable} />}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default KycReactTable;
