// React and related imports
import React, { useContext, useEffect } from "react";
// Style import
import "./PaymentsCollectionDataTable.scss";
// Custom components
import TimeCell from "../../utilities/TimeCell";
import ReactDataTable from "../Common/ReactDataTable";
import ShowTransactionModalCell from "./ShowTransactionModalCell";
import StatusCell from "../../utilities/StatusCell/StatusCell";
import UpiCollection from "./UpiPaymentCollectionModal/UpiCollection/UpiCollection";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import BalanceCell from "../../utilities/BalanceCell";
// Context provider
import { PaymentsCollectionContextProvider } from "./PaymentsCollectionContext";
// Third-party libraries
import posthog from "posthog-js";
// Utility functions
import { capturePosthogEvent } from "../../utilities/posthogUtils";
import { capturePosthogEventCustom } from "../../utilities/postHogCustomUtils";

const transactionColumnList = [
  {
    accessor: "decentro_transaction_id",
    name: "Decentro Transaction ID",
    showInitial: true,
    cell: ShowTransactionModalCell,
  },
  {
    accessor: "bank_reference_number",
    name: "Bank Ref",
    showInitial: true,
  },
  {
    accessor: "timestamp",
    name: "Date-Time",
    cell: TimeCell,
    showInitial: true,
  },
  {
    accessor: "money_transfer_status",
    hasPopup: true,
    name: "Transaction status",
    showInitial: true,
    cell: StatusCell,
  },
  { accessor: "provider", name: "provider", showInitial: true, hasPopup: true },
  { accessor: "npci_txn_id", name: "NPCI TXN ID", showInitial: true },
  { accessor: "amount", name: "amount", showInitial: true, cell: BalanceCell },
  {
    accessor: "collection_request_status",
    name: "Request status",
    hasPopup: true,
    showInitial: true,
    cell: StatusCell,
  },
  {
    accessor: "customer_reference_number",
    name: "Customer ref num",
    showInitial: true,
  },
  {
    accessor: "payee_consumer_bank_account_number",
    name: "Payee",
  },
  { accessor: "payer_consumer_bank_account_number", name: "Payer" },
  { accessor: "payee_vpa", name: "Payee vpa" },
  { accessor: "payer_vpa", name: "payer vpa" },
  { accessor: "commission_amount", name: "commission amount" },
];
const UpiCollectionCTA = ({ openUpiLink }) => {
  const { isLoading } = useContext(PaymentsCollectionContextProvider);
  return (
    <>
      <button
        className={isLoading ? "btn-loading" : "virtual-accounts-btn"}
        style={{ backgroundColor: "#0092ff" }}
        onClick={() => {
          if (!isLoading) {
            if (
              process.env.REACT_APP_ENV_NAME === "staging" ||
              process.env.REACT_APP_ENV_NAME === "production"
            ) {
              if (window?.location?.origin && window?.location?.pathname) {
                try {
                  capturePosthogEventCustom(
                    window.location.origin,
                    window.location.pathname,
                    "Send Collection Request CTA Click",
                    {
                      additionalInfo:
                        "User clicked Send Collection Request button",
                    }
                  );
                } catch (error) {
                  console.error("Error capturing Posthog event:", error);
                }
              } else {
                console.warn(
                  "Unable to capture event: window.location properties are not fully defined"
                );
              }
            }
            openUpiLink();
          }
        }}
      >
        Send Collection Request
      </button>
    </>
  );
};

const PaymentsCollectionReactTable = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (window?.location?.origin && window?.location?.pathname) {
        try {
          capturePosthogEventCustom(
            window.location.origin,
            window.location.pathname,
            "Collection Requests View",
            {
              additionalInfo: "User viewed Collection Requests page",
            }
          );
        } catch (error) {
          console.error("Error capturing Posthog event:", error);
        }
      } else {
        console.warn(
          "Unable to capture event: window.location properties are not fully defined"
        );
      }
    }
  }, []);
  const {
    initialLoading,
    isError,
    errorResponse,
    refreshTable,
    setShowModalDialog,
    showModalDialog,
    tableData,
    totalCount,
    filterList,
    fetchData,
    firstApiCall,
  } = useContext(PaymentsCollectionContextProvider);
  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);
  function openUpiLink() {
    setShowModalDialog(true);
  }

  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={"Collection Requests"}
          isModalPresent={true}
          modalComponent={<UpiCollectionCTA openUpiLink={openUpiLink} />}
          columnList={transactionColumnList}
          provider={PaymentsCollectionContextProvider}
          ModalDialog={<UpiCollection goBackToTable={refreshTable} />}
          showDownloadBtn={false}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default PaymentsCollectionReactTable;
