import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

// *********** NAVIGATION COMPONENTS ************************
import { useLocation, useHistory } from "react-router-dom";
import { Redirect, Route } from "react-router-dom";
import paths from "../../config/Setup/modulepathsstaging";
import TabPanel from "../../components/TabPanel/TabPanel.js";
import SubmenuBar from "../../components/SubmenuBar/SubmenuBar";
import { modulesContext } from "../../contexts/modulesContext";
import modules from "../../config/Setup/modules_staging.json";

// ********* STYLES *******************
import "./StagingDashboard.scss";

// *********** ACCOUNTS, PAYMENTS AND RECURRING COLLECTIONS ***************************
import AccountsAnalytics from "../../components/AccountsAnalytics/AccountsAnalytics";
import VirtualAccountContext from "../../components/VirtualAccountDataTable/VirtualAccountContext";
import VirtualAccountReactDatatable from "../../components/VirtualAccountDataTable/VirtualAccountReactDatatable.js";
import AllTransactionsDatatable from "../../components/AllTransactionsDatatable/AllTransactionsDatatable";
import Reports from "../../components/Reports/Reports";
import PaymentsAnalytics from "../../components/PaymentsAnalytics/PaymentsAnalytics";
import AccountBalanceContext from "../../components/AccountManagement/AccountBalanceContext";
import AccountBalanceTable from "../../components/AccountManagement/AccountBalanceTable";
import CallBackContext from "../../components/CallbackConfiguration/CallBackContext";
import CallBackTable from "../../components/CallbackConfiguration/CallBackTable";
// import AccountBalances from "../../components/AccountManagement/AccountBalances/AccountBalances";
// import CallbackConfiguration from "../../components/CallbackConfiguration/CallbackConfiguration";
// import { CallbackActionProvider } from "../../contexts/CallbackActionContext";
import PaymentsTransactionContext from "../../components/PaymentsTransactionsDataTable/PaymentsTransactionContext";
import ManageBeneficiaryReact from "../../components/ManageBeneficiary/ManageBeneficiaryReact";
import BeneficiaryContext from "../../components/ManageBeneficiary/ManageBeneficiaryContext";

import PaymentsLinkContext from "../../components/PaymentsLinkDataTable/PaymentsLinkContext";
import PaymentsCollectionContext from "../../components/PaymentsCollectionDataTable/PaymentsCollectionContext";
import PayoutsContext from "../../components/Payouts/PayoutsContext";
import SettlementsContext from "../../components/Settlements/SettlementsContext";
import PaymentsLinkReactTable from "../../components/PaymentsLinkDataTable/PaymentsLinkReactTable";
import PayoutsReactTable from "../../components/Payouts/PayoutsReactTable";
import PaymentTransactionsReactTable from "../../components/PaymentsTransactionsDataTable/PaymentsTransactionReactTable";
import SettlementReact from "../../components/Settlements/SettlementsReact";
import PaymentsCollectionReactTable from "../../components/PaymentsCollectionDataTable/PaymentsCollectionReactTable";
import { CallbackActionProvider } from "../../contexts/CallbackActionContext.js";
import ValidateBankAccounts from "../../components/ValidateBankAccounts/ValidateBankAccounts.js";
import ValidateAccountsContext from "../../components/ValidateBankAccounts/ValidateAccountsContext.js";
import BulkCapabilities from "../../components/BulkCapabilities/BulkCapabilities.js";
import BulkCapabilitiesContext from "../../components/BulkCapabilities/Contexts/BulkCapabilitiesContext.js";
import DownloadReports from "../../components/DownloadReports/DownloadReports.js";
import DownloadReportsContext from "../../components/DownloadReports/Contexts/DownloadReportsContext.js";
import ManageIPContext from "../../components/ManageIPs/ManageIPContext.js";
import ManageIPs from "../../components/ManageIPs/ManageIPs.js";

// ***********  ONBOARIDNG  *********
import FinancialServicesAnalytics from "../../components/FinancialServicesAnalytics/FinancialServicesAnalytics";
import KycAnalytics from "../../components/KycAnalytics/KycAnalytics";
import KycContext from "../../components/KycDataTable/KycContext";
import KycReactTable from "../../components/KycDataTable/KycReactTable";
import KycAadharContext from "../../components/KycAndhaarXML/KycAadharContext";
import KycAadharReactTable from "../../components/KycAndhaarXML/KycAadharReactTable";
import CkycSearchContext from "../../components/CkycSearchAndDownload/CkycSearchContext";
import CkycSearchAndDownloadReact from "../../components/CkycSearchAndDownload/CkycSearchAndDownloadReact";
import FaceMatchContext from "../../components/FaceMatch/FaceMatchContext";
import FaceMatchReact from "../../components/FaceMatch/FaceMatchReact";
import TextMatchContext from "../../components/TextMatch/TextMatchContext";
import TextMatchReact from "../../components/TextMatch/TextMatchReact";
import IdClassificationContext from "../../components/IdClassification/IdClassificationContext";
import IdClassificationReact from "../../components/IdClassification/IdClassificationReact";
import LivelinessContext from "../../components/LivelinessCheck/LivelinessContext";
import LivelinessReactTable from "../../components/LivelinessCheck/LivelinessReactTable";
import AadharUIDContext from "../../components/AadharUIDMasking/AadharUIDContext";
import AadharUIDMaskingReact from "../../components/AadharUIDMasking/AadharUIDMaskingReact";
import DigiLockerContext from "../../components/DigiLocker/DigiLockerContext";
import DigiLockerReact from "../../components/DigiLocker/DigiLockerReact";
import CreditReportContext from "../../components/FinancialServicesCreditReport/CreditReportContext";
import FinancialServicesCreditReportTable from "../../components/FinancialServicesCreditReport/FinancialServicesCreditReportTable";

import SnackbarMessage from "../../components/Shared/SnackbarMessage/SnackbarMessage";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/core_banking/endpoint";
import { clearAuthTokens } from "axios-jwt";
import CreditHistoryContext from "../../components/CreditHistory/CreditHistoryContext.js";
import CreditHistoryTable from "../../components/CreditHistory/CreditHistoryTable.js";

// ********************** These imports are commented becuase there are no backend apis present in staging *******************
// import PaymentsCreditsContext from "../../components/PaymentsCredits/PaymentsCreditsContext";
// import PaymentsCreditsReactTable from "../../components/PaymentsCredits/PaymentsCreditsReact";
// import KycCreditHistoryContext from "../../components/KYCCreditHistory/KycCreditHistoryContext";
// import KycCreditReact from "../../components/KYCCreditHistory/KycCreditReact";
// import FinancialCreditContext from "../../components/FinancialCredits/FinancialCreditContext";
// import FinancialCreditTable from "../../components/FinancialCredits/FinancialCreditTable";
// import DataPullContext from "../../components/DataPull/DataPullContext";
// import DataPullTable from "../../components/DataPull/DataPullTable";
// import PPICreditsContext from "../../components/PPICredits/PPICreditsContext";
// import PPICreditsReact from "../../components/PPICredits/PPICreditsReact";
// import ViewCreditsContext from "../../components/ViewCredits/ViewCreditsContext";
// import ViewCreditsTable from "../../components/ViewCredits/ViewCreditsTable";
// import EmandateContext from "../../components/RecurringCollection/Emandate/EmandateContext";
// import EmandateTable from "../../components/RecurringCollection/Emandate/EmandateTable";

const StagingDashboard = () => {
  const location = useLocation();
  const history = useHistory();
  const stagingPage = location.pathname.split("/")[2];
  const [currentModuleData, setCurrentModuleData] = useState(
    modules.modules_staging[0]
  );

  const [lastActivityTime, setLastActivityTime] = useState(new Date());
  const handleUserActivity = () => {
    setLastActivityTime(new Date());
  };
  useEffect(() => {
    const clickListener = () => {
      handleUserActivity();
    };
    document.addEventListener("click", clickListener);
    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, []);
  const logout = () => {
    // Unmount any component at the "failure-popup" div
    const failurePopupDiv = document.getElementById("failure-popup");
    ReactDOM.unmountComponentAtNode(failurePopupDiv);
    APIConfig.API_Client.post(
      apiEndpointList.USER_LOGOUT.baseUrl + apiEndpointList.USER_LOGOUT.endpoint
    ).then((response) => {
      clearAuthTokens();
      history.push("/login");
    });
  };
  useEffect(() => {
    const inactivityTimer = setInterval(() => {
      const currentTime = new Date();
      const elapsedTime = currentTime - lastActivityTime;
      const inactivityThreshold = 30 * 60 * 1000;
      if (elapsedTime >= inactivityThreshold) {
        // Show snackbar and logout user (clear auth tokens)
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={"Session Expired. Please login again."}
          />,
          document.getElementById("snackbar")
        );
        logout();
        clearInterval(inactivityTimer);
      }
    }, 1000);
    return () => {
      clearInterval(inactivityTimer);
    };
  }, [lastActivityTime, history]);

  return (
    <React.Fragment>
      <Route path="/dashboard">
        {stagingPage === "accounts" ||
          stagingPage === "payments" ||
          stagingPage === "kyc" ||
          stagingPage === "reports" ||
          stagingPage === "manage-account" ||
          stagingPage === "financial-services" ||
          stagingPage === "configuration" ||
          stagingPage === "validate-bank-account" ||
          stagingPage === "bulk-capabilities" ||
          stagingPage === "manage-ips" ||
          stagingPage === "others" ? (
          <Redirect to={location.pathname} />
        ) : (
          <Redirect to={paths.ACCOUNTS_ANALYTICS} />
        )}
      </Route>
      <div className="staging-dashboard">
        <modulesContext.Provider
          value={{ currentModuleData, setCurrentModuleData }}
        >
          <TabPanel />
          <div className="submenu-and-content">
            <SubmenuBar />
            <div className="content">
              <VirtualAccountContext>
                <Route path={paths.ACCOUNTS_ALL_ACCOUNTS}>
                  <VirtualAccountReactDatatable />
                </Route>
              </VirtualAccountContext>

              <Route path={paths.ACCOUNTS_TRANSACTIONS}>
                <AllTransactionsDatatable />
              </Route>

              <AccountBalanceContext>
                <Route path={paths.ACCOUNTS_BALANCES}>
                  <AccountBalanceTable />
                </Route>
              </AccountBalanceContext>

              <Route path={paths.ACCOUNTS_ANALYTICS}>
                <AccountsAnalytics />
              </Route>

              {/* <Route path={paths.ACCOUNTS_REPORTS}>
                <Reports />
              </Route> */}

              <PaymentsTransactionContext>
                <Route path={paths.PAYMENTS_TRANSACTIONS}>
                  <PaymentTransactionsReactTable />
                </Route>
              </PaymentsTransactionContext>

              <PaymentsLinkContext>
                <Route path={paths.PAYMENTS_PAYMENT_LINKS}>
                  <PaymentsLinkReactTable />
                </Route>
              </PaymentsLinkContext>

              <PayoutsContext>
                <Route path={paths.PAYMENTS_PAYOUTS}>
                  <PayoutsReactTable />
                </Route>
              </PayoutsContext>

              {/* --BULK_CAPABILITY: Uncomment when required. Current Status: Is being used.*/}
              <BulkCapabilitiesContext>
                <Route path={paths.BULK_CAPABILITIES}>
                  <BulkCapabilities />
                </Route>
              </BulkCapabilitiesContext>

              <Route path={paths.PAYMENTS_ANALYTICS}>
                <PaymentsAnalytics />
              </Route>

              <PaymentsCollectionContext>
                <Route path={paths.PAYMENTS_COLLECTION_REQUESTS}>
                  <PaymentsCollectionReactTable />
                </Route>
              </PaymentsCollectionContext>
              <ValidateAccountsContext>
                <Route path={paths.PAYMENTS_VALIDATE_ACCOUNTS}>
                  <ValidateBankAccounts />
                </Route>
              </ValidateAccountsContext>

              {/* <SettlementsContext>
                <Route path={paths.PAYMENTS_COLLECTION_SETTLEMENTS}>
                  <SettlementReact />
                </Route>
              </SettlementsContext> */}

              <KycContext>
                <Route path={paths.KYC_VALIDATE}>
                  <KycReactTable />
                </Route>
              </KycContext>

              <KycAadharContext>
                <Route path={paths.KYC_ANDHAAR_XML}>
                  <KycAadharReactTable />
                </Route>
              </KycAadharContext>

              <CkycSearchContext>
                <Route path={paths.CKYC_SEARCH_AND_DOWNLOAD}>
                  <CkycSearchAndDownloadReact />
                </Route>
              </CkycSearchContext>

              <FaceMatchContext>
                <Route path={paths.KYC_FACEMATCH}>
                  <FaceMatchReact />
                </Route>
              </FaceMatchContext>

              <TextMatchContext>
                <Route path={paths.KYC_TEXTMATCH}>
                  <TextMatchReact />
                </Route>
              </TextMatchContext>

              <IdClassificationContext>
                <Route path={paths.KYC_ID_CLASSIFICATION}>
                  <IdClassificationReact />
                </Route>
              </IdClassificationContext>

              <LivelinessContext>
                <Route path={paths.KYC_LIVELINESS_CHECK}>
                  <LivelinessReactTable />
                </Route>
              </LivelinessContext>

              <AadharUIDContext>
                <Route path={paths.KYC_AADHAAR_UID_MASKING}>
                  <AadharUIDMaskingReact />
                </Route>
              </AadharUIDContext>

              <DigiLockerContext>
                <Route path={paths.KYC_DIGILOCKER}>
                  <DigiLockerReact />
                </Route>
              </DigiLockerContext>

              <Route path={paths.KYC_ANALYTICS}>
                <KycAnalytics />
              </Route>

              <CreditReportContext>
                <Route path={paths.FINANCIAL_SERVICES_CREDIT_REPORT}>
                  <FinancialServicesCreditReportTable />
                </Route>
              </CreditReportContext>

              <Route path={paths.FINANCIAL_SERVICES_ANALYTICS}>
                <FinancialServicesAnalytics />
              </Route>

              <BeneficiaryContext>
                <Route path={paths.PAYMENTS_MANAGE_BENEFICIARY}>
                  <ManageBeneficiaryReact />
                </Route>
              </BeneficiaryContext>

              <Route path={paths.CONFIGURATION_CALLBACKS}>
                <CallBackContext>
                  <CallbackActionProvider>
                    <CallBackTable />
                  </CallbackActionProvider>
                </CallBackContext>
              </Route>
              <Route path={paths.MANAGE_IP}>
                <ManageIPContext>
                  <ManageIPs />
                </ManageIPContext>
              </Route>

              {/* Reports Section */}
              <Route path={paths.REPORTS_CAS}>
                <Reports />
              </Route>

              <Route path={paths.DOWNLOAD_REPORTS}>
                <DownloadReportsContext>
                  <DownloadReports />
                </DownloadReportsContext>
              </Route>
              {/* Credit History merge section */}
              <Route path={paths.REPORTS_CREDIT_HISTORY}>
                <CreditHistoryContext>
                  <CreditHistoryTable />
                </CreditHistoryContext>
              </Route>

              {/* ******* These components are commented because the apis are not present in the backend ******* */}

              {/* <PaymentsCreditsContext>
                  <Route path={paths.PAYMENTS_CREDITS}>
                    <PaymentsCreditsReactTable />
                  </Route>
                </PaymentsCreditsContext> */}
              {/* <KycCreditHistoryContext>
                <Route path={paths.KYC_CREDITS}>
                  <KycCreditReact />
                </Route>
              </KycCreditHistoryContext> */}
              {/* <PPICreditsContext>
                <Route path={paths.PPI_CREDITS}>
                  <PPICreditsReact />
                </Route>
              </PPICreditsContext> */}
              {/* <FinancialCreditContext>
                <Route path={paths.FINANCIAL_SERVICES_CREDITS}>
                  <FinancialCreditTable />
                </Route>
              </FinancialCreditContext> */}
              {/* <DataPullContext>
                <Route path={paths.FINANCIAL_SERVICES_DATA_PULL}>
                  <DataPullTable />
                </Route>
              </DataPullContext> */}
              {/* <ViewCreditsContext>
                <Route path={paths.VIEW_CREDITS}>
                  <ViewCreditsTable />
                </Route>
              </ViewCreditsContext> */}
              {/* <EmandateContext>
                <Route path={paths.RECURRING_COLLECTION_EMANDATE}>
                  <EmandateTable />
                </Route>
              </EmandateContext> */}
              {/* <Route path={paths.CONFIGURATION_VIEW_CALLBACKS}>
                <ViewCallbackSummary />
              </Route> */}
            </div>
          </div>
        </modulesContext.Provider>
      </div>
    </React.Fragment>
  );
};

export default StagingDashboard;
