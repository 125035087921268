import React from "react";
import ReactDOM from "react-dom";

import { Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// STYLES
import "./DataTableModalCollection.scss";
import { data } from "jquery";

function DataTableModal({ data }) {


  return (
    <div>
      <ModalComponent {...data = data} />
    </div>

  );
}

function ModalComponent(data) {
  // Modal unmount function
  function copyToClipBoard() {
    navigator.clipboard.writeText
      (data.payment_link);
  }
  const closeTableDetailModal = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("virtual-accounts-components")
    );
  };
  return (
    <React.Fragment>
      <Dialog
        className="va-details"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-popup">
          <CloseIcon onClick={closeTableDetailModal} />
        </div>

        <DialogContent>
          <div className="va-details-heading">
            <p>Payment Collection Details</p>
          </div>
          <div className="va-details-container">
            {/* LEFT - BLOCK */}

            <div className="left-detail-block">
              <table>
                <tbody>
                  <tr>
                    <th>Decentro Transaction ID</th>
                    <td>{data.decentro_transaction_id}</td>
                  </tr>

                  <tr>
                    <th>Bank Reference Number</th>
                    <td>{data.bank_reference_number}</td>
                  </tr>

                  <tr>
                    <th>NPCI Transaction ID</th>
                    <td>{data.npci_txn_id}</td>
                  </tr>

                  <tr>
                    <th>Customer Reference Number</th>
                    <td>{data.customer_reference_number}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{data.status}</td>
                  </tr>


                  <tr>
                    <th>Provider</th>
                    <td>{data.provider}</td>
                  </tr>

                  <tr>
                    <th>Amount</th>
                    <td>{data.amount}</td>
                  </tr>

                  <tr>
                    <th>Payee Account Number</th>
                    <td>{data.payee_consumer_bank_account_number}</td>
                  </tr>

                  <tr>
                    <th>Payer Account Number</th>
                    <td>{data.payer_consumer_bank_account_number}</td>
                  </tr>

                  <tr>
                    <th>Payee IFSC</th>
                    <td>{data.payee_ifsc}</td>
                  </tr>

                  <tr>
                    <th>Payer IFSC</th>
                    <td>{data.payer_ifsc}</td>
                  </tr>
                  <tr>
                    <th>Commission Amount</th>
                    <td>{data.commission_amount}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* RIGHT - BLOCK */}

            <div className="right-qr-block-links">
              <img
                className="qr-image"
                src="/images/No QR.svg"
                alt="replace this with qr code"
              />


            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default DataTableModal;
