import React from "react";
import ReactDOM from "react-dom";

import { Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SnackbarMessage from "../../Shared/SnackbarMessage/SnackbarMessage";

// styles import
import "./DataTableModalLinks.scss";

function DataTableModal({ data }) {
  return (
    <div>
      <ModalComponent {...(data = data)} />
    </div>
  );
}

function ModalComponent(data) {
  // Modal unmount function

  let QrCodeView;

  if (data.status === "Pending" && data.qr_code) {
    QrCodeView = (
      <img
        className="qr_code_img"
        src={
          data.qr_code && data.qr_code !== "-"
            ? "data:image/jpeg;base64," + data.qr_code
            : "/images/No QR.svg"
        }
        alt="pending QR code"
      />
    );
  } else {
    QrCodeView = (
      <img
        className="qr-image"
        src="/images/No QR.svg"
        alt="replace this with qr code"
      />
    );
  }

  function copyToClipBoard() {
    navigator.clipboard.writeText(data.payment_link);
    ReactDOM.render(
      <SnackbarMessage msg={"The Link has been copied"} />,
      document.getElementById("snackbar")
    );
  }
  const closeTableDetailModal = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("virtual-accounts-components")
    );
  };
  return (
    <React.Fragment>
      <Dialog
        className="va-details"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-popup">
          <CloseIcon onClick={closeTableDetailModal} />
        </div>

        <DialogContent>
          <div className="va-details-heading">
            <p>Payment Link Details</p>
          </div>
          <div className="va-details-container">
            {/* LEFT - BLOCK */}

            <div className="left-detail-block">
              <table>
                <tbody>
                  <tr>
                    <th>Decentro Transaction ID</th>
                    <td>{data?.decentro_transaction_id || "-"}</td>
                  </tr>

                  <tr>
                    <th>Bank Reference Number</th>
                    <td>{data?.bank_reference_number || "-"}</td>
                  </tr>

                  <tr>
                    <th>NPCI Transaction ID</th>
                    <td>{data?.npci_txn_id || "-"}</td>
                  </tr>

                  <tr>
                    <th>Customer Reference Number</th>
                    <td>{data?.customer_reference_number || "-"}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{data?.status || "-"}</td>
                  </tr>

                  <tr>
                    <th>Provider</th>
                    <td>{data?.provider || "-"}</td>
                  </tr>

                  <tr>
                    <th>Amount</th>
                    <td>{data?.amount || "-"}</td>
                  </tr>

                  <tr>
                    <th>Payee Account Number</th>
                    <td>{data?.payee_consumer_bank_account_number || "-"}</td>
                  </tr>

                  <tr>
                    <th>Payer Account Number</th>
                    <td>{data?.payer_consumer_bank_account_number || "-"}</td>
                  </tr>

                  <tr>
                    <th>Payee IFSC</th>
                    <td>{data?.payee_ifsc || "-"}</td>
                  </tr>

                  <tr>
                    <th>Payer IFSC</th>
                    <td>{data?.payer_ifsc || "-"}</td>
                  </tr>
                  <tr>
                    <th>Commission Amount</th>
                    <td>{data?.commission_amount || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* RIGHT - BLOCK */}

            <div className="right-qr-block-links-payment">
              {/* QR code view */}
              {QrCodeView}

              {data.status === "Pending" ? (
                <div className="links">
                  {/* <a
                    href={data?.payment_link}
                    rel="noopener noreferrer"
                    target="_blank"
                  > */}
                  {data?.payment_link || "-"}
                  {/* </a> */}
                  <button
                    className="download-btn"
                    onClick={() => copyToClipBoard()}
                  >
                    <img className="copy-img" src="/images/copy-icon.svg" />
                  </button>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default DataTableModal;
