import React from "react";
import ReactDOM from "react-dom";

import { Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// STYLES
import "./TransactionModal.scss";
// UTILS
import { data } from "jquery";
import { formatDateToCustomFormat } from "../../Utils/utils";
import FormatBalance from "../../../utilities/FormatBalance";

function TransactionModal({ data }) {
  return (
    <div>
      <ModalComponent {...(data = data)} />
    </div>
  );
}

function ModalComponent(data) {
  // Modal unmount function
  const closeTableDetailModal = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("virtual-accounts-components")
    );
  };

  return (
    <React.Fragment>
      <Dialog
        className="va-details"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-popup">
          <CloseIcon onClick={closeTableDetailModal} />
        </div>

        <DialogContent>
          <div className="va-details-heading">
            <p>Transaction Details</p>
          </div>
          <div className="va-details-container">
            {/* LEFT - BLOCK */}

            <div className="left-detail-block">
              <table>
                <tbody>
                  <tr>
                    <th>Amount</th>
                    <td>₹ {FormatBalance(data?.amount)}</td>
                  </tr>

                  <tr>
                    <th>Bank</th>
                    <td>{data.provider}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{data.status}</td>
                  </tr>
                  <tr>
                    <th>Decentro Transaction ID</th>
                    <td>{data.decentro_transaction_id}</td>
                  </tr>

                  <tr>
                    <th>Customer Reference Number</th>
                    <td>{data.customer_reference_number}</td>
                  </tr>

                  <tr>
                    <th>Commission Amount</th>
                    <td>{FormatBalance(data?.commission_amount)}</td>
                  </tr>

                  <tr>
                    <th>Bank Reference Number</th>
                    <td>{data.bank_reference_number}</td>
                  </tr>

                  <tr>
                    <th>Date</th>
                    <td>{formatDateToCustomFormat(data?.date)}</td>
                  </tr>

                  <tr>
                    <th>Payment Method</th>
                    <td>{data.transaction_type}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default TransactionModal;
