import { useContext, useEffect } from "react";
// Components
import ReactDataTableWithoutCustomerRef from "../Common/ReactDataTableWithoutCustomerRef";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import ValidateAccount from "./ValidateAccountModal/ValidateAccount";
import TimeCell from "../../utilities/TimeCell";
// Contexts
import { ValidateAccountsContextProvider } from "./ValidateAccountsContext";
// Utils
import posthog from "posthog-js";
import StatusCell from "../../utilities/StatusCell/StatusCell";
import { capturePosthogEvent } from "../../utilities/posthogUtils";
import { capturePosthogEventCustom } from "../../utilities/postHogCustomUtils";

const transactionColumnList = [
  {
    accessor: "decentro_urn",
    name: "Decentro Transaction ID",
    showInitial: true,
  },
  {
    accessor: "beneficiary_account_number",
    name: "Beneficiary Account Number",
    showInitial: true,
  },
  {
    accessor: "beneficiary_ifsc_code",
    name: "Beneficiary IFSC Code",
    showInitial: true,
  },
  {
    accessor: "beneficiary_name",
    name: "Beneficiary Name",
    showInitial: true,
  },
  {
    accessor: "transaction_status",
    name: "Transaction Status",
    hasPopup: true,
    showInitial: true,
    cell: StatusCell,
  },
  {
    accessor: "request_timestamp",
    name: "Created On",
    cell: TimeCell,
    showInitial: true,
  },
  {
    accessor: "account_status",
    name: "Account Status",
    hasPopup: true,
    showInitial: true,
    cell: StatusCell,
  },
  {
    accessor: "bank_reference_number",
    name: "Bank Reference Number",
    showInitial: true,
  },
  {
    accessor: "message",
    name: "Message",
    showInitial: true,
  },
];

// CTA for Validating Account
const ValidateCTA = ({ openCTA }) => {
  const { isLoading } = useContext(ValidateAccountsContextProvider);

  return (
    <>
      <button
        className={isLoading ? "btn-loading" : "virtual-accounts-btn"}
        style={{ backgroundColor: "#0092ff" }}
        onClick={() => {
          if (!isLoading) {
            if (
              process.env.REACT_APP_ENV_NAME === "staging" ||
              process.env.REACT_APP_ENV_NAME === "production"
            ) {
              if (window?.location?.origin && window?.location?.pathname) {
                try {
                  capturePosthogEventCustom(
                    window.location.origin,
                    window.location.pathname,
                    "Validate Bank Account CTA Click",
                    {
                      additionalInfo:
                        "User clicked Validate Bank Account button",
                    }
                  );
                } catch (error) {
                  console.error("Error capturing Posthog event:", error);
                }
              } else {
                console.warn(
                  "Unable to capture event: window.location properties are not fully defined"
                );
              }
            }
            openCTA();
          }
        }}
      >
        Validate Bank Account
      </button>
    </>
  );
};

const ValidateBankAccounts = () => {
  const {
    initialLoading,
    isError,
    errorResponse,
    refreshTable,
    setShowModalDialog,
    showModalDialog,
    tableData,
    totalCount,
    filterList,
    fetchData,
    firstApiCall,
  } = useContext(ValidateAccountsContextProvider);

  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (window?.location?.origin && window?.location?.pathname) {
        try {
          capturePosthogEventCustom(
            window.location.origin,
            window.location.pathname,
            "Validate Bank Account View",
            {
              additionalInfo: "User viewed Validate Bank Account page",
            }
          );
        } catch (error) {
          console.error("Error capturing Posthog event:", error);
        }
      } else {
        console.warn(
          "Unable to capture event: window.location properties are not fully defined"
        );
      }
    }
  }, []);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);

  function openCTA() {
    setShowModalDialog(true);
  }

  return (
    <>
      {!isError ? (
        <ReactDataTableWithoutCustomerRef
          tableTitle={"Validate Accounts"}
          isModalPresent={true}
          modalComponent={<ValidateCTA openCTA={openCTA} />}
          columnList={transactionColumnList}
          provider={ValidateAccountsContextProvider}
          ModalDialog={<ValidateAccount goBackToTable={refreshTable} />}
          showDownloadBtn={false}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default ValidateBankAccounts;
