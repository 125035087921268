// React and related imports
import React from "react";
import { useEffect, useContext } from "react";
// Style import
import "./PaymentsLinkDataTable.scss";
// Custom components
import TimeCell from "../../utilities/TimeCell";
import ShowPaymentLinkModalCell from "./ShowPaymentLinkModalCell";
import ReactDataTable from "../Common/ReactDataTable";
import ShowPaymentLinkCell from "./ShowPaymentLinkCell";
import StatusCell from "../../utilities/StatusCell/StatusCell";
import UpiPaymentLink from "./UpiPaymentLinkModal/UpiPaymentLink/UpiPaymentLink";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import BalanceCell from "../../utilities/BalanceCell";
// Context provider
import { PaymentsLinkContextProvider } from "./PaymentsLinkContext";
// Third-party libraries
import posthog from "posthog-js";
// Utility functions
import { capturePosthogEvent } from "../../utilities/posthogUtils";
import { capturePosthogEventCustom } from "../../utilities/postHogCustomUtils";

const paymentColumnList = [
  {
    accessor: "decentro_transaction_id",
    name: "Decentro Transaction ID",
    showInitial: true,
    cell: ShowPaymentLinkModalCell,
  },
  {
    accessor: "bank_reference_number",
    name: "Bank Ref",
    showInitial: true,
  },
  {
    accessor: "timestamp",
    name: "Date-Time",
    cell: TimeCell,
    showInitial: true,
  },
  {
    accessor: "status",
    name: "status",
    showInitial: true,
    hasPopup: true,
    cell: StatusCell,
  },
  { accessor: "provider", name: "provider", showInitial: true, hasPopup: true },
  { accessor: "npci_txn_id", name: "NPCI Txn ID", showInitial: true },
  {
    accessor: "payment_link",
    name: "Payment Link",
    showInitial: true,
    cell: ShowPaymentLinkCell,
  },
  { accessor: "amount", name: "Amount", showInitial: true, cell: BalanceCell },
  { accessor: "customer_reference_number", name: "Customer ref num" },
  {
    accessor: "payee_consumer_bank_account_number",
    name: "Payee",
  },
  // { accessor: "payee_vpa", name: "Payee vpa" },
  // { accessor: "payer_consumer_bank_account_number", name: "Payer" },
  { accessor: "payer_vpa", name: "Payer vpa" },
];
const UpiLinkCTA = ({ openUpiLink }) => {
  const { isLoading } = useContext(PaymentsLinkContextProvider);
  return (
    <>
      <button
        className={isLoading ? "btn-loading" : "virtual-accounts-btn"}
        style={{ backgroundColor: "#0092ff" }}
        onClick={() => {
          if (!isLoading) {
            if (
              process.env.REACT_APP_ENV_NAME === "staging" ||
              process.env.REACT_APP_ENV_NAME === "production"
            ) {
              if (window?.location?.origin && window?.location?.pathname) {
                try {
                  capturePosthogEventCustom(
                    window.location.origin,
                    window.location.pathname,
                    "Generate Payment Link CTA Click",
                    {
                      additionalInfo:
                        "User clicked Generate Payment Link button",
                    }
                  );
                } catch (error) {
                  console.error("Error capturing Posthog event:", error);
                }
              } else {
                console.warn(
                  "Unable to capture event: window.location properties are not fully defined"
                );
              }
            }
            openUpiLink();
          }
        }}
      >
        Generate Payment Link
      </button>
    </>
  );
};
const PaymentsLinkReactTable = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (window?.location?.origin && window?.location?.pathname) {
        try {
          capturePosthogEventCustom(
            window.location.origin,
            window.location.pathname,
            "Payment Links View",
            {
              additionalInfo: "User viewed Payment Links page",
            }
          );
        } catch (error) {
          console.error("Error capturing Posthog event:", error);
        }
      } else {
        console.warn(
          "Unable to capture event: window.location properties are not fully defined"
        );
      }
    }
  }, []);
  const {
    initialLoading,
    isError,
    errorResponse,
    refreshTable,
    setShowModalDialog,
    showModalDialog,
    tableData,
    filterList,
    totalCount,
    firstApiCall,
    fetchData,
    isLoading,
  } = useContext(PaymentsLinkContextProvider);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);
  function openUpiLink() {
    setShowModalDialog(true);
  }
  return (
    <>
      {!isError ? (
        <ReactDataTable
          columnList={paymentColumnList}
          isModalPresent={true}
          modalComponent={<UpiLinkCTA openUpiLink={openUpiLink} />}
          tableTitle={"Payment Links"}
          provider={PaymentsLinkContextProvider}
          ModalDialog={<UpiPaymentLink goBackToTable={refreshTable} />}
          showDownloadBtn={false}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default PaymentsLinkReactTable;
