import React from "react";

// styles import
import "./FeatureUnavailable.scss";

const FeatureUnavailable = () => {
  return (
    <div className="warn-wrapper">
      <p class="warn-text">
        Feature currently not available for this environment
      </p>
    </div>
  );
};

export default FeatureUnavailable;
