// React and related imports
import React, { useContext, useEffect } from "react";
// Context provider
import { CreditHistoryContextProvider } from "./CreditHistoryContext";
// Custom components
import ReactDataTable from "../Common/ReactDataTable";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
// Utility functions
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/core_banking/endpoint";
import axios from "axios";
import TimeCell from "../../utilities/TimeCell";
import { capturePosthogEventCustom } from "../../utilities/postHogCustomUtils";

const transactionColumnList = [
  {
    accessor: "decentro_urn",
    name: "Decentro Transaction ID",
    showInitial: true,
  },
  {
    accessor: "api_name",
    name: "API Name",
    showInitial: true,
  },
  { accessor: "module_name", name: "Module Name", showInitial: true },
  {
    accessor: "timestamp",
    name: "Timestamp",
    cell: TimeCell,
    showInitial: true,
  },
  { accessor: "credits", name: "Credits", showInitial: true },
];

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const CreditHistoryTable = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (window?.location?.origin && window?.location?.pathname) {
        try {
          capturePosthogEventCustom(
            window.location.origin,
            window.location.pathname,
            "Credit History View",
            {
              additionalInfo: "User viewed Credit History page",
            }
          );
        } catch (error) {
          console.error("Error capturing Posthog event:", error);
        }
      } else {
        console.warn(
          "Unable to capture event: window.location properties are not fully defined"
        );
      }
    }
  }, []);

  const {
    isError,
    errorResponse,
    totalCount,
    tableData,
    filterList,
    firstApiCall,
    fetchData,
  } = useContext(CreditHistoryContextProvider);

  const [modulesList, setModulesList] = React.useState([]);
  // ^ Getting Modules List
  function getModules() {
    APIConfig.API_Client.post(
      apiEndpointList.GET_MODULES_BY_COMPANY.baseUrl +
      apiEndpointList.GET_MODULES_BY_COMPANY.endpoint,
      { cancelToken: source.token }
    )
      .then((response) => {
        const responseResult = response.data.map((item) =>
          item.name === "All" ? { ...item, code: "all" } : item
        );
        // * Filters only active modules
        let onlyActiveModule = responseResult.filter(
          (moduleIsActive) => moduleIsActive.is_active === true
        );
        setModulesList(onlyActiveModule);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getModules();
  }, []);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);

  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={"Credit History"}
          columnList={transactionColumnList}
          provider={CreditHistoryContextProvider}
          isModalPresent={false}
          showDownloadBtn={false}
          showModuleDropdown={true}
          modulesData={modulesList}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};
export default CreditHistoryTable;
