import React, { useState, useEffect, useContext, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import modules from "../../config/Setup/modules_staging.json";
import { modulesContext } from "../../contexts/modulesContext";
import "./Tabs.scss";

const TabsStaging = () => {
  const location = useLocation();
  const history = useHistory();
  const [latestPath, setLatestPath] = useState("");
  const [modulesData, setModulesData] = useState(modules.modules_staging);
  const [tabActive, setTabActive] = useState("0");
  const { currentModuleData, setCurrentModuleData } =
    useContext(modulesContext);

  if (window.history && window.history.pushState) {
    window.addEventListener("popstate", function () {
      toggleTab(localStorage.getItem("currentActiveTabIndex"));
    });
  }

  const toggleTab = (index) => {
    setTabActive(index);
    localStorage.setItem("currentActiveTabIndex", index);
  };

  useEffect(() => {
    setLatestPath(location.pathname);
    if (localStorage.getItem("currentActiveTabIndex") === null) {
      localStorage.setItem("currentActiveTabIndex", "0");
      setTabActive("0");
      localStorage.setItem(
        "currentSubmenu",
        JSON.stringify(modules.modules_staging[0])
      );
    } else {
      toggleTab(localStorage.getItem("currentActiveTabIndex"));
      updateSubmenus(JSON.parse(localStorage.getItem("currentSubmenu")));
    }
  }, []);

  useEffect(() => {
    setLatestPath(location.pathname);
  }, [tabActive]);

  const tabReverseUpdate = (index) => {
    setTabActive(index);
    localStorage.setItem("currentActiveTabIndex", index);
    updateSubmenus(
      JSON.parse(
        document.getElementsByClassName("tab-link")[index].dataset.item
      )
    );
    localStorage.setItem(
      "currentSubmenu",
      JSON.stringify(
        document.getElementsByClassName("tab-link")[index].dataset.item
      )
    );
  };

  const subMenuList = modules.modules_staging.map((item) =>
    item.submenus.map((subItem) => subItem.module)
  );

  useEffect(() => {
    const newtabIndex = subMenuList.findIndex((item) => {
      return (
        item.findIndex((subItemName) => {
          return location.pathname?.includes(subItemName);
        }) !== -1
      );
    });
    if (newtabIndex === -1) {
      tabReverseUpdate("0");
      return;
    }
    tabReverseUpdate(newtabIndex.toString());
  }, [location.pathname]);

  const updateSubmenus = (item) => {
    setCurrentModuleData(item);
    localStorage.setItem("currentSubmenu", JSON.stringify(item));
  };

  return (
    <div className="menu-tabs">
      {modulesData.map((item, index) => {
        return (
          <div
            key={index}
            className={tabActive == index ? "tab active" : "tab"}
          >
            <NavLink
              key={index}
              className={tabActive == index ? "tab-link active" : "tab-link"}
              to={item.path}
              data-path={item.path}
              data-reference={item.module}
              data-item={JSON.stringify(item)}
              onClick={() => {
                toggleTab(index);
                updateSubmenus(item);
              }}
            >
              {item.display_name}
            </NavLink>
          </div>
        );
      })}
    </div>
  );
};

export default TabsStaging;
