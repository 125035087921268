// React and related imports
import React, { useContext, useEffect } from "react";
import posthog from "posthog-js";
// Context provider
import { LivelinessContextContextProvider } from "./LivelinessContext";
// Custom components
import ReactDataTable from "../Common/ReactDataTable";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
// Utility functions
import TimeCell from "../../utilities/TimeCell";
import { capturePosthogEventCustom } from "../../utilities/postHogCustomUtils";
// Styles
import "./LivelinessCheck.scss";

const transactionColumnList = [
  {
    accessor: "decentro_urn",
    name: "Decentro Transaction ID",
    showInitial: true,
  },
  {
    accessor: "response_code",
    name: "Response Code",
    showInitial: true,
  },
  {
    accessor: "time_stamp",
    name: "Date-Time",
    cell: TimeCell,
    showInitial: true,
  },
  { accessor: "api_status", name: "API Status", showInitial: true },
  { accessor: "response_message", name: "Message", showInitial: true },
  // { accessor: "document_type", name: "Document type" },
  {
    accessor: "customer_reference_id",
    name: "Customer Ref No.",
    showInitial: true,
  },
];

const LivelinessReactTable = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (window?.location?.origin && window?.location?.pathname) {
        try {
          capturePosthogEventCustom(
            window.location.origin,
            window.location.pathname,
            "Video Liveness View",
            {
              additionalInfo: "User viewed Video Liveness page",
            }
          );
        } catch (error) {
          console.error("Error capturing Posthog event:", error);
        }
      } else {
        console.warn(
          "Unable to capture event: window.location properties are not fully defined"
        );
      }
    }
  }, []);
  const {
    isError,
    errorResponse,
    tableData,
    totalCount,
    fetchData,
    filterList,
    firstApiCall,
  } = useContext(LivelinessContextContextProvider);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);

  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={"Video Liveness"}
          provider={LivelinessContextContextProvider}
          columnList={transactionColumnList}
          isModalPresent={false}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default LivelinessReactTable;
